exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-demo-js": () => import("./../../../src/templates/demo.js" /* webpackChunkName: "component---src-templates-demo-js" */),
  "component---src-templates-gdpr-js": () => import("./../../../src/templates/gdpr.js" /* webpackChunkName: "component---src-templates-gdpr-js" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-legals-js": () => import("./../../../src/templates/legals.js" /* webpackChunkName: "component---src-templates-legals-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/Reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-references-js": () => import("./../../../src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

